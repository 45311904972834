@include mq-add-breakpoint("footer-foot-separate", 992px);


.footer {}

  // FOOT
  .footer__foot {
    background: $color-red;
    color: $color-white;
    display: flex;
    padding: rem(20px);
    text-align: center;

    @include mq($until: "footer-foot-separate") {
      align-items: center;
      flex-direction: column;
    }

    @include mq($from: "footer-foot-separate") {
      justify-content: space-between;
    }
  }

    .footer__credit {}

    .footer__legals {
      @include mq($until: "footer-foot-separate") {
        margin-top: rem(20px);
      }
    }

      .footer__legals-list {
        display: flex;
        flex-wrap: wrap;
        margin: rem(-10px -15px);

        @include mq($until: "footer-foot-separate") {
          justify-content: center;
        }
      }

        .footer__legals-item {
          padding: rem(10px 15px);
        }

          .footer__link {
            font-weight: $font-bold;
            text-decoration: underline;

            &:hover,
            &:focus {
              text-decoration: none;
            }
          }
