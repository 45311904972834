$echo-portrait-size: em(60px, $base-font-size);

.c-echo {
  backface-visibility: hidden;
  background: #fff;
  border-radius: em(100px, $base-font-size);
  box-shadow: rem(0px 4px 4px) rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  line-height: (20 / 16);
  padding: em(15px, $base-font-size);
  position: relative;
  transition: transform 0.25s;

  &:hover, &:focus {
    text-decoration: none;
    transform: translateY(-1rem);
  }
}

  .c-echo__picture {
    flex: 0 0 auto;
    position: relative;
    height: $echo-portrait-size;
    width: $echo-portrait-size;
  }

    .c-echo__portrait {
      border: 2px solid $color-primary;
      border-radius: 100%;
    }

    .c-echo__bars {
      bottom: em(8px, $base-font-size);
      height: em(24px, $base-font-size);
      left: 0;
      letter-spacing: -0.2em;
      position: absolute;
      right: 0;
      text-align: center;
    }

      .c-echo__bar {
        background: #fff;
        display: inline-block;
        height: 100%;
        margin: 0 0.5px;
        vertical-align: middle;
        width: 2px;
        animation-duration: 1s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;

        .c-echo.is-playing & {
          animation-name: echo-bars;
        }

        &:nth-child(1),
        &:nth-child(3),
        &:nth-child(6),
        &:nth-child(8),
        &:nth-child(10) { height: 43.75%; animation-delay: 0.5s; }

        &:nth-child(4),
        &:nth-child(9) { height: 70.41%; animation-delay: 0.25s;}

        &:nth-child(5) { height: 87.5%; animation-delay: 0.75s; }
      }

  .c-echo__description {
    padding-left: em(15px, $base-font-size);
  }

    .c-echo__label {
      color: $color-primary;
      font-size: em(14px, $base-font-size);
      font-weight: $font-bold;
    }

    .c-echo__author {
      line-height: 1;
    }

      .c-echo__name {
        display: block;
      }

      .c-echo__job {
        font-size: em(12px, $base-font-size);
      }

  .c-echo__audio {
    display: none;
  }

// https://codepen.io/JohnCoxsey/pen/qRROp
@keyframes echo-bars {
  9% { height: 85.71%; }
  18% { height: 78.57%; }
  27% { height: 71.42%; }
  36% { height: 64.28%; }
  45% { height: 57.14%; }
  54% { height: 50%; }
  63% { height: 42.85%; }
  72% { height: 35.71%; }
  81% { height: 28.57%; }
  90% { height: 100%; }
  100% { height: 85.71%; }
}

// Floating echo
.c-echo--floating {
  left: 50%;
  position: absolute;
  overflow: hidden;
  margin-left: rem(-145px);
  white-space: nowrap;
  width: rem(290px);

  &:hover, &:focus {
    transform: translateY(-1rem);
  }

  .c-echo__description {
    flex: 0 0 auto;
    overflow: hidden;
    white-space: normal;
    width: 20rem;
  }

  &.is-animate {
    animation: echo-fade-in 1s;
  }
}

@keyframes echo-fade-in {
  0% {
    margin-left: rem(-45px);
    opacity: 0;
    transform: translateY(2rem);
    width: rem(90px);
  }
  50% {
    margin-left: rem(-45px);
    transform: translateY(0);
    width: rem(90px);
  }
  100% {
    width: rem(290px);
  }
}
