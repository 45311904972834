$starwars-perspective: 500px;
$starwars-perspective-rotation: 25deg;

.c-starwars {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  min-height: calc(100vh - var(--vh-offset) - var(--stay-tuned-offset));
  padding-bottom: rem(125px);
  position: relative;
  overflow-x: clip;

  &.is-initialized {
    display: block;
    height: 100vh;
    height: calc(100vh - var(--vh-offset) - var(--stay-tuned-offset));
    padding-bottom: 0;
    overflow: hidden;
  }

  @include mq($until: large) {
    padding-top: rem(125px);
  }

  @include mq($from: large) {
    &:not(.is-initialized) {
      padding-top: rem(175px);
    }
  }
}

  .c-starwars__inner {
    height: 100%;
    overflow: hidden;
    mask-image: linear-gradient(to bottom, transparent 20px, black 170px);

    .c-starwars:not(.is-initialized) & {
      display: flex;
      align-items: center;
      overflow: visible;
      mask-image: none;
    }
  }

  .c-starwars__sunset {
    left: 50%;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: -1;

    &::before {
      content: "";
      background: $color-sunset;
      border-radius: 100%;
      display: block;
      padding-bottom: 100%;
      position: absolute;
      width: 100%;
      transform: translate(0, -50%);

    }

    @media only screen and (orientation: landscape) {
      width: 125vh;
    }

    @media only screen and (orientation: portrait) {
      width: 125vw;
    }
  }

  .is-initialized .c-starwars__perspective {
    height: 100%;
    position: relative;
    transform: perspective($starwars-perspective) translateY(-$starwars-perspective/2) rotateX($starwars-perspective-rotation);
    transform-style: preserve-3d;
  }

  .c-starwars__list {
    text-align: center;

    .is-initialized & {
      left: 0;
      padding: 0;
      position: absolute;
      top: 100%;
      transform-style: preserve-3d;
      width: 100%;

      &.no-transition {
        transition: none;
      }
    }
  }

    .is-initialized .c-starwars__group {
      padding-bottom: calc(50vh);
      backface-visibility: hidden;
      transform: translate3d(0, 0, 0);
      transform-style: preserve-3d;
    }

    .c-starwars__item {
      padding: rem(20px);
      // max-width: rem(600px);
      font-size: 50px;
      animation-range: unset;
      animation: zstack linear;
      animation-timeline: view();
      width:100%;

      @include mq($from: large) {
        padding: rem(50px) rem(25px);
      }

      .is-initialized & {
      }
      @media(prefers-reduced-motion: reduce) {
        @include mq($from: large) {
          padding: rem(100px) rem(25px);
        }
      }

      @media(prefers-reduced-motion: no-preference) {
        @keyframes zstack {
          0% {
            opacity: 1;
            transform: scale(2) translateY(100%);
          }
        80% {
            opacity: 1;
          }
          90% {
            opacity: 0;
            transform: scale(0.5) translateY(0%);
          }
        }
      }

    }

      .c-starwars__link {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        transition: scale 150ms;

        .is-initialized & {
          transform-style: preserve-3d;
          transform: translate3d(0, 0, 0) rotateX(-$starwars-perspective-rotation);
          transform-origin: 0 100%;
        }

        @include mq($from: large) {
          &:hover, &:focus {
            color: $color-salmon;
            text-decoration: none;
          }
        }

        &:hover, &:focus {
          scale: 1.2;
        }
      }

      .c-starwars__portrait {
        border-radius: 50%;
        left: 50%;
        opacity: 0;
        position: absolute;
        top: 0%;
        transform: translate(-50%, -70%);
        visibility: hidden;
        z-index: -1;
        transition: opacity 0.25s, visibility 0.25s;

        @include mq($from: large) {
          opacity: 1;
          visibility: visible;
        }
      }

      .c-starwars__number {
        color: $color-salmon;
        background-color: #A82618;
        border: solid rem(2px) #fff;
        border-radius: rem(10px);
        display: inline-block;
        font-size: rem(20px);
        line-height: (14 / 20);
        transform: rotate(-2deg);
        padding: 0.4em 0.4em;
      }

      .c-starwars__name {
        font-family: $font-secondary;
        @include font-size(20px, 20px);
        font-weight: $font-regular;
        color: #A82618;
        span {
          background: rgba(#fff, 1);
          padding: rem(5px) rem(10px);
          border-radius: rem(12px);
          box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  line-height: 1.2;
        }
        @include mq($from: large) {
          font-size: rem(30px);
        }
      }

      .c-starwars__job {
        font-family: $font-secondary;
        margin-top: rem(10px);
        @include font-size(14px, 18px);
        color: #fff;

        @include mq($from: large) {
          font-size: rem(20px);
        }
      }
