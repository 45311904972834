// Colors (https://flatuicolors.com)
$color-turquoise: #1abc9c;
$color-green-sea: #16a085;
$color-emerald: #2ecc71;
$color-nephritis: #27ae60;
$color-peter-river: #3498db;
$color-belize-hole: #2980b9;
$color-amethyst: #9b59b6;
$color-wisteria: #8e44ad;
$color-wet-asphalt: #34495e;
$color-midnight-blue: #2c3e50;
$color-sun-flower: #f1c40f;
$color-orange: #f39c12;
$color-carrot: #e67e22;
$color-pumpkin: #d35400;
$color-alizarin: #e74c3c;
$color-pomegranate: #c0392b;
$color-clouds: #ecf0f1;
$color-silver: #bdc3c7;
$color-concrete: #95a5a6;
$color-asbestos: #7f8c8d;

$color-red: #A82618;
$color-salmon: #FFD2D2;
$color-purple: #B4318A;
$color-gold: #FFAF1F;
$color-blue: #1388CF;
$color-sunset: #A82618;
$color-white: #FFF;

$color-background: $color-salmon;
$color-foreground: #181818;

$color-primary: $color-red;
$color-secondary: $color-wet-asphalt;

$color-success: $color-nephritis;
$color-error: $color-pomegranate;

// Fonts
$font-default: Karmilla, sans-serif;
$font-primary: $font-default;
$font-secondary: Clashdisplay, sans-serif;
$font-serif: Georgia, Times, "Times New Roman", serif;
$font-code: Inconsolata, Monaco, Consolas, "Andale Mono", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;

// Font weights
$font-regular: 400;
$font-bold: 600;

// Font-size + line-height
$base-font-size: 16px;
$base-line-height: 24px;

// Z-indexes
$z-header: 10;
$z-main: 1;
$z-modal: 30;
$z-cursor: 40;
$z-looney: 35;
$z-footer: 20;

// Dimensions
$gutter-size: 20px;
$container-width: 1100px;

// Breakpoints
$mq-breakpoints: (
  small: 480px,
  medium: 720px,
  large: 960px
);

// Rem
$rem-fallback: false;
$rem-baseline: 10px;

// Resolution
$resolution-default: 2;

// Sprites
$sprite-pixel-ratio: $resolution-default;
$sprite-asset-pipeline: true;
$sprite-rem: true;
$sprite-rem-fallback: $rem-fallback;
$sprite-rem-baseline: $rem-fallback;
