.c-hero {
  backface-visibility: hidden;
  height: 100vh;
  height: calc(100vh - var(--vh-offset) - var(--stay-tuned-offset) + #{rem(20px)});
  overflow: hidden;
  padding-bottom: rem(20px);
  position: relative;
}

  .c-hero__plane {
    height: 100%;
    perspective: 5vw;
    position: relative;
    width: 100%;
  }

  .c-hero__logo {
    // font-size: 100vh;
    // height: 1em; // 124px
    // width: 1.532em; // 190px
    font-size: 110vw;
    height: 0.652em;
    width: 1em;
    max-width: none;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transform-origin: 50% 50%;

    &.is-animate {
      animation: zoom-in 1.5s;
    }
  }

    .c-hero__echo {
      @include mq($until: medium) {
        bottom: rem(75px);
      }

      @include mq($from: medium) {
        bottom: rem(140px);
      }
    }

@keyframes zoom-in {
  0% { transform: translate(-50%, -50%) scale(0);  }
  60% {  transform: translate(-50%, -50%) scale(3.5); }
  100% { transform: translate(-50%, -50%) scale(1); }
}
