.c-people {
  position: relative;
  text-align: center;
}

  .c-people__figure {
    padding-bottom: 10rem;
    position: relative;
    z-index: 0;
  }

    .c-people__portrait {
      position: relative;

      &:not(:first-child) {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
      }

      &:first-child { z-index: 5; }
      &:nth-child(2) { top: 2rem; z-index: 4; }
      &:nth-child(3) { top: 4rem; z-index: 3; }
      &:nth-child(4) { top: 6rem; z-index: 2; }
      &:nth-child(5) { top: 8rem; z-index: 1; }
    }

  .c-people__identity {
    background: #fff;
    bottom: 0;
    border-radius: 50%;
    color: $color-foreground;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: rem(160px);
    left: 50%;
    padding: rem(20px);
    position: absolute;
    transform: translateX(-50%);
    width: rem(160px);
    z-index: 1;
  }

    .c-people__name {
      @include font-size(24px, 28px);
      font-weight: $font-bold;
    }

    .c-people__job {
      line-height: (28px / $base-font-size);
    }
