.c-text {
  font-size: rem(22px);
  line-height: (36 / 30);
  text-align: center;

  &--big {
    font-family: $font-secondary;
    font-size: rem(30px);
  }

  * {
    margin: 0;
  }

  * + * {
    margin-top: 1em;
  }
}


@include mq($from: large) {

  .c-text {
    font-size: rem(30px);

    &--big {
      font-size: rem(60px);
      line-height: (70 / 60);
    }
  }

}
